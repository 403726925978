import {getWebsiteInfoConfigApi, getWebsitePageConfigApi} from "@/network/interface";


export default {
  /* 获取网站配置信息*/
  getWebsiteInfoConfig(context) {
    getWebsiteInfoConfigApi().then(res => {
      context.commit("getWebsiteInfoConfigMut", res.data)
      return res
    }, error => {
    })
  },
  /*获取页面配置信息*/
  getWebsitePageConfig(context) {
    getWebsitePageConfigApi().then(resp => {
      context.commit('getWebsitePageConfigMut', resp.data)
    }, error => {
    })
  },
}