import Vue from 'vue'
import Vuex from 'vuex'
import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 网站详情信息
    WebsiteInfoConfig: {
      // 博主信息
      blogInfo: {
        username: "",
        blog_name: "",
        user_image_url: "",
        motto: "",
        email: "",
        qq_account: "",
        github_link: "",
        gitee_link: "",
        about: "",
      },
      // 博客信息
      webInfo: {
        total_view: "",
        notice: "",
        website_title: "",
        website_create_time: "",
        article_count: "",
        tag_count: "",
        cateorie_count: "",
        website_records: ""
      },
      countInfo: {
        article_count: "",
        categorie_count: "",
        tag_count: "",
        visits_count: ""
      }
    },
    // 网站页面配置
    WebsitePageConfig: {
      background: {}
    }
  },
  mutations: mutations,
  actions: actions,
  getters: getters,
  modules: {}
})
