import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueImageSwipe from 'vue-image-swipe'
import 'vue-image-swipe/dist/vue-image-swipe.css'
import "./assets/css/vue-social-share/client.css";
import Share from "vue-social-share";
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style



import animated from 'animate.css'
import {vueBaberrage} from 'vue-baberrage'

import './assets/css/base.css'
import './assets/css/iconfont.css'
import './assets/css/markdown.css'
import 'aplayer/dist/APlayer.min.css';

import Toast from "./components/toast/index";

NProgress.configure({ showSpinner: false }) // NProgress Configuration

Vue.use(animated)
Vue.use(Toast)
Vue.use(vueBaberrage) // 弹幕
Vue.use(VueImageSwipe) // 图片放大
Vue.use(Share); // 分享按钮
Vue.use(NProgress); // 进度条

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  NProgress.start()
  document.title = to.meta.title
  next()
})


router.afterEach((to, from) => {
  NProgress.done()
})


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
