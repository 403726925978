<template>
  <v-snackbar v-model="show" top :timeout="2000" :color="color">
    <v-icon>{{iconText}}</v-icon>
    <span class="message">  {{ message }}</span>

  </v-snackbar>
</template>

<script>
  export default {
    name: "Toast",
    data() {
      return {
        message: "",
        timeout: 2000,
        show: false,
        state: "normal",
        iconText: "",
        color: "",
      }
    },
    watch: {
      state(value) {
        if (value === "success") {
          this.color = "#70a3f5"
          this.iconText = "mdi-emoticon-excited"

        }
        if (value === "error") {
          this.color = "#f56c6c"
          this.iconText = "mdi-emoticon-dead"
        }
      }
    }
  }
</script>


<style scoped>


  .message {
    margin-left: 8px !important;
  }

</style>
