export default {
  blogInfo(state) {
    return state.WebsiteInfoConfig.blogInfo
  },
  webInfo(state) {
    return state.WebsiteInfoConfig.webInfo
  },
  countInfo(state) {
    return state.WebsiteInfoConfig.countInfo
  },
  getBackground(state) {
    return function (name) {
      return state.WebsitePageConfig.background[name]
    }
  }
}