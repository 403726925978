import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import('../views/home/Home'),
    meta: {
      title: '首页'
    }
  },
  {
    path: "/link",
    name: "link",
    component: () => import('../views/link/Link'),
    meta: {
      title: '友链'
    }
  },
  {
    path: "/about",
    name: "about",
    component: () => import('../views/about/About'),
    meta: {
      title: '关于'
    }
  },
  {
    path: '/archives',
    name: "archives",
    component: () => import('../views/archives/Archives'),
    meta: {
      title: '归档'
    }
  },
  {
    path: '/categories',
    name: "categories",
    component: () => import('../views/categories/Categories'),
    meta: {
      title: '分类'
    }
  },
  {
    path: "/tags",
    name: "tags",
    component: () => import('../views/tag/Tag'),
    meta: {
      title: '标签'
    }
  },
  {
    path: "/tags/:id",
    name: "tags_id",
    component: () => import('../views/article/ArticlesList'),
    meta: {
      title: '标签详情'
    }
  },
  {
    path: "/categories/:id",
    name: "categories_id",
    component: () => import('../views/article/ArticlesList'),
    meta: {
      title: '分类详情'
    }
  },

  {
    path: "/messages",
    name: "messages",
    component: () => import('../views/message/Message'),
    meta: {
      title: '留言'
    }
  },
  {
    path: "/articles/:id",
    name: "articles",
    component: () => import('../views/article/Articles'),
    meta: {
      title: '文章详情'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) { // 刷新页面自动回到顶部
    return {
      x: 0,
      y: 0
    }
  }
})


export default router
