<template>
    <div>
        <v-app-bar dark hide-on-scroll height="58" :class="navClass" fixed flat color="rgba(0, 0, 0, 0)">
            <v-app-bar-title><a href="/" class="bar-title">{{ webInfo.website_title }}</a></v-app-bar-title>
            <v-spacer></v-spacer>
            <div class="bar-nav d-none d-md-flex">
                <ul>
                    <li>
                        <div @click="dialog = !dialog" class="bar-nav-item">
                            <v-icon dense class="pr-2">mdi-magnify</v-icon>
                            搜索
                        </div>
                    </li>
                    <li>
                        <router-link tag="div" class="bar-nav-item" to="/">
                            <v-icon dense class="pr-2">mdi-home</v-icon>
                            首页
                        </router-link>
                    </li>
                    <li class="bar-nav-item-find">
                        <div class="bar-nav-item">
                            <v-icon dense class="pr-2">mdi-compass</v-icon>
                            发现
                            <v-icon dense class="mr-0">mdi-chevron-down</v-icon>
                        </div>
                        <ul class="sub-nav-find animate__animated animate__fadeIn">
                            <router-link to="/archives" tag="li" class="sub-item">
                                <v-icon color="#4c4948">mdi-dresser</v-icon>
                                归档
                            </router-link>
                            <router-link to="/categories" tag="li" class="sub-item">
                                <v-icon color="#4c4948">mdi-view-grid</v-icon>
                                分类
                            </router-link>
                            <router-link to="/tags" tag="li" class="sub-item">
                                <v-icon color="#4c4948">mdi-tag-outline</v-icon>
                                标签
                            </router-link>

                        </ul>

                    </li>
                    <li>
                        <router-link tag="div" class="bar-nav-item" to="/link">
                            <v-icon dense class="pr-2">mdi-link-variant</v-icon>
                            友链
                        </router-link>
                    </li>
                    <li>
                        <router-link tag="div" class="bar-nav-item" to="/about">
                            <v-icon dense class="pr-2">mdi-rocket-launch-outline</v-icon>
                            关于
                        </router-link>
                    </li>
                    <li>
                        <router-link tag="div" class="bar-nav-item" to="/messages">
                            <v-icon dense class="pr-2">mdi-chat-processing</v-icon>
                            留言
                        </router-link>
                    </li>
                </ul>
            </div>

            <div @click="dialog = !dialog" class="d-md-none pr-3">
                <v-icon dense class="md-search pr-1">mdi-magnify</v-icon>
                搜索
            </div>
            <v-app-bar-nav-icon id="sidebar" :class="navClass" class="bar-nav-icon   d-md-none"
                                @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        </v-app-bar>

        <!--侧导航栏-->
        <v-navigation-drawer v-model="drawer"
                             app
                             overlay-opacity="0.8"
                             disable-resize-watcher
                             fixed left temporary>
            <!--头像-->
            <div class="blog-info">
                <v-avatar size="128">
                    <img :src="blogInfo.user_image_url" alt="">
                </v-avatar>
            </div>

            <!--博主信息-->
            <div class="blog-info-wrapper">
                <div class="info-wrapper">
                    <a href="#">
                        <div>文章</div>
                        <div>{{ countInfo.article_count }}</div>
                    </a>
                </div>
                <div class="info-wrapper">
                    <a href="#">
                        <div>分类</div>
                        <div>{{ countInfo.categorie_count }}</div>
                    </a></div>
                <div class="info-wrapper">
                    <a href="#">
                        <div>标签</div>
                        <div>{{ countInfo.tag_count }}</div>
                    </a>
                </div>
            </div>
            <!--分割线-->
            <hr/>
            <!--页面导航-->
            <div class="navigations">
                <router-link to="/" tag="div" class="navigation">
                    <v-icon color="">mdi-home</v-icon>
                    首页
                </router-link>
                <router-link to="/archives" tag="div" class="navigation">
                    <v-icon>mdi-dresser</v-icon>
                    归档
                </router-link>
                <router-link to="/categories" tag="div" class="navigation">
                    <v-icon>mdi-view-grid</v-icon>
                    分类
                </router-link>
                <router-link to="/tags" tag="div" class="navigation">
                    <v-icon>mdi-tag-outline</v-icon>
                    标签
                </router-link>

                <router-link to="/link" tag="div" class="navigation">
                    <v-icon>mdi-link-variant</v-icon>
                    友链
                </router-link>

                <router-link to="/about" tag="div" class="navigation">
                    <v-icon>mdi-rocket-launch-outline</v-icon>
                    关于
                </router-link>
                <router-link to="/messages" tag="div" class="navigation">
                    <v-icon>mdi-chat-processing</v-icon>
                    留言
                </router-link>
            </div>
        </v-navigation-drawer>

        <!--搜索框-->
        <v-dialog v-model="dialog" width="600">
            <v-card class="search-wrapper">
                <div class="mb-3">
                    <span class="search-title">本地搜索</span>
                    <!-- 关闭按钮 -->
                    <v-icon class="float-right" color="#757575" @click="dialog = false">mdi-close
                    </v-icon>
                </div>
                <!--搜索框-->
                <!-- 输入框 -->
                <div class="search-input-wrapper">
                    <v-icon color="#757575">mdi-magnify</v-icon>
                    <input v-model="search" placeholder="输入文章标题..."/>
                    <v-icon @click="emptySearch" color="#757575">mdi-close-circle-outline</v-icon>

                </div>
                <div class="search-result-wrapper">
                    <hr class="divider"></hr>
                    <div class="search-result">
                        <div :key="item.id" v-for=" item in search_data"
                             class="search-article animate__animated animate__fadeInUp">
                            <div @click="toArticle(item.id)" class="search-article-title">
                                {{item.articles_title}}
                            </div>
                            <div class="search-article-describe" v-text="item.article_describe">
                            </div>
                        </div>
                        <div v-if="search.length >= 2 && search_data.length===0 && !search_wait"
                             class="not-search-title">
                            {{search}}:没有找到相关文章
                        </div>
                        <div v-if="search.length ===1" class="not-search-title">
                            需要两个以上关键字查询
                        </div>
                        <div v-if="search_wait" class="not-search-title">
                            正在查询关键字:{{search}}
                        </div>
                    </div>
                </div>
            </v-card>
        </v-dialog>
    </div>

</template>

<script>

  import {WebsiteInfoConfigMixin} from "@/components/mixin"
  import {RealTimeSearchApi} from "@/network/interface"

  export default {
    name: "Banner",
    mixins: [
      WebsiteInfoConfigMixin
    ],
    data() {
      return {
        navClass: "nav",
        scrollTop: 0,
        invertedScroll: true,
        drawer: false,
        dialog: false,
        search: '',
        search_data: [],
        search_wait: false
      }
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll)
    },
    watch: {
      search: function (nval, oval) {
        if (!(nval.length < 2)) {
          const this_ = this
          this.search_wait = true
          setTimeout(() => {
            RealTimeSearchApi({search: this_.search}).then(resp => {
              this_.search_wait = false
              this_.search_data = resp.data
            })
          }, 1000)
        } else {
          this.search_data = []
        }


      }
    },
    methods: {
      toArticle(id) {
        this.dialog = false
        this.search = ''
        this.$router.push('/articles/' + id)
      },
      emptySearch() {
        this.search = ''
      },
      handleScroll(scrollEvent) {
        // 滚动条距文档顶部的距离
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop ||
          document.body.scrollTop
        // 滚动条滚动的距离
        this.scrollTop = scrollTop
        if (this.scrollTop < 60) {
          this.navClass = 'nav'
        } else {
          this.navClass = 'nav-fixed'
        }
      },
    },
    components: {}
  }
</script>

<style scoped>

    .nav {
        background: rgba(0, 0, 0, 0) !important;
        color: #eeeeee !important;
    }

    .nav .md-search {
        color: #eeeeee;
    }


    .nav .bar-title, .nav .bar-nav-icon, .nav .bar-nav .bar-nav-item, .nav .bar-nav-item > i {
        color: #ffffff !important;
    }

    .nav-fixed {
        background: rgba(255, 255, 255, 0.8) !important;
        color: #4c4948 !important;
    }

    .nav-fixed #sidebar {
        color: #4c4948 !important;
        background: rgba(0, 0, 0, 0) !important;
    }

    .bar-nav-icon {
        background-color: rgba(0, 0, 0, 0) !important;
    }


    .nav-fixed #sidebar span.v-btn__content {
        background: rgba(0, 0, 0, 0) !important;
    }


    .nav-fixed .v-icon {
        color: #4c4948;
    }

    .bar-nav .bar-nav-item:after {
        content: '';
        position: absolute;
        display: block;
        bottom: 0;
        left: 0;
        right: 0;
        height: 2px;
        transform: translateX(-110%);
        background-color: #49b1f5;
        transition: all 0.5s ease;
    }


    .bar-nav .bar-nav-item:hover:after {
        transform: translateX(0%);
    }

    .bar-nav-item {
        position: relative;
        overflow-x: hidden;
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: 1rem;
    }

    .bar-nav-item .v-icon {
        font-size: 1.2rem !important;
    }


    .bar-nav li {
        position: relative;
        float: left;
        margin: 0 10px;
    }


    .bar-nav .sub-nav-find {
        display: none;
        position: absolute;
        padding: 0;
        background-color: #fefefe;
        animation-duration: 0.5s;
        font-size: 0.8rem;
    }

    .sub-nav-find .v-icon {
        font-size: 1.2rem !important;
    }

    .bar-nav-item-find {
        margin: 0 0 0 10px !important;
    }


    .bar-nav-item-find:hover .sub-nav-find {
        display: block;
    }

    .sub-nav-find:hover {
        display: block;
    }

    .sub-nav-find .sub-item {
        width: 100%;
        text-align: center;
        padding: 10px 0 !important;
        margin: 0 !important;
        color: #4c4948 !important;
    }

    .sub-item:hover {
        background-color: #4ab0f3;
    }

    .bar-nav li i {
        padding-right: 5px !important;
        padding-left: 0;
    }

    .search-result-wrapper {
        overflow: auto;
    }

    /*.search-result-wrapper::-webkit-scrollbar {*/
    /*    display: none; !* Chrome Safari *!*/
    /*}*/
    .search-article {
        margin-top: .5rem;
        padding-bottom: 1rem;
        border-bottom: 2px dashed #d2ebfd;
    }

    .search-article-title {
        color: #64a1e8;
        margin-bottom: 0.5rem;
        font-size: 1.2rem;
        font-weight: 700;
        text-decoration: underline;
    }

    .search-article-describe {

        height: 6rem;
        color: #343436;
        overflow: hidden;
        text-overflow: ellipsis;
        /* 弹性伸缩盒子模型显示 */
        display: -webkit-box;
        /* 限制在一个块元素显示的文本的行数 */
        -webkit-line-clamp: 4;
        /* 设置或检索伸缩盒对象的子元素的排列方式 */
        -webkit-box-orient: vertical;

    }

    /*侧导航栏*/

    .blog-info {
        padding: 26px 30px 0;
        display: flex;
        justify-content: center;
    }


    .blog-info-wrapper {
        display: flex;

    }

    .blog-info-wrapper .info-wrapper {
        margin-top: 20px;
        text-align: center;
        flex: 1;
    }


    .info-wrapper div:first-child {
        font-size: 0.875rem;
    }

    .info-wrapper div:last-child {
        font-size: 1.125rem;
        padding-top: 10px;
    }

    hr {
        border: 2px dashed #d2ebfd;
        margin: 20px 0;
    }

    .navigations {
        color: #4c4948 !important;
        margin: 30px 0 !important;
    }


    .navigations .v-icon {
        color: #4c4948 !important;
    }

    .navigations .navigation {
        padding: 0 50px 25px;

    }

    .navigation i {
        padding-right: 30px !important;
    }


    .search-wrapper {
        background-color: #ffffff !important;
        padding: 1.25rem;
        height: 100%;
    }

    .search-title {
        color: #49b1f5;
        font-size: 1.5rem;
    }

    .search-input-wrapper {
        display: flex;
        padding: 5px;
        height: 35px;
        width: 100%;
        border: 2px solid #8e8cd8;
        border-radius: 2rem !important;


    }

    .search-input-wrapper input {
        width: 100%;
        outline: none;

    }

    .divider {
        margin: 20px 0 10px 0;
        border: 2px dashed #d2ebfd;
    }

    .search-result {
        height: 60vh;
    }


    /*夜间效果*/

    .theme--dark .nav-fixed #sidebar {
        color: #eeeeee !important;
        background: rgba(0, 0, 0, 0) !important;
    }


    .theme--dark .nav-fixed {
        background: #1e1e1e !important;
        color: #eeeeee !important;
    }

    .theme--dark .nav-fixed .bar-nav-item > .v-icon {
        background: #1e1e1e !important;
        color: #eeeeee;
    }

    .theme--dark .nav-fixed .md-search {
        color: #eeeeee;
    }

    .theme--dark .nav-fixed .bar-title {
        color: #eeeeee !important;
    }

    .theme--dark .blog-info-wrapper a div {
        color: #eeeeee;
    }

    .theme--dark .navigations .navigation {
        color: #eeeeee;
    }

    .theme--dark .navigations .navigation .v-icon {
        color: #eeeeee !important;
    }

    .theme--dark .search-wrapper {
        background-color: #21262b !important;

    }

    .theme--dark .search-article-describe {
        color: #f1d4d4;
    }

    .theme--dark .search-input-wrapper input {
        color: #f1d4d4;
    }

    @media screen and (max-width: 750px) {
        .bar-title {
            white-space: nowrap;
            font-size: 18px;
            font-weight: 700;
        }
    }
</style>
