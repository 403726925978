import {mapGetters} from "vuex";

export const WebsiteInfoConfigMixin = {
  computed: {
    ...mapGetters([
      'blogInfo',
      'webInfo',
      'countInfo'
    ]),
    qqUrl() {
      return "http://wpa.qq.com/msgrd?v=3&uin=" + this.blogInfo.qq_account + "&site=qq&menu=yes"
    },
    githubUrl() {
      return this.blogInfo.github_link
    },
    giteeUrl() {
      return this.blogInfo.gitee_link
    }

  },

}

