<template>
    <v-app>
        <nav-bar/>
        <v-content class="pb-5">
            <router-view :key="key"/>
        </v-content>

        <!--音乐播放器-->
        <player class=" d-md-flex d-none
            "/>
        <!--返回顶部-->
        <back-top/>
        <Footer v-show="$route.path !== '/messages'"></Footer>
    </v-app>
</template>

<script>
  import NavBar from "./components/layout/NavBar";
  import Footer from "./components/layout/Footer";
  import BackTop from "./components/BackTop";
  import Player from "./components/player/Player";
  import {mapActions} from 'vuex'

  export default {
    name: 'App',
    created() {
      this.getWebsiteInfoConfig()
      this.getWebsitePageConfig()

    },
    methods: {
      ...mapActions([
        'getWebsiteInfoConfig',
        'getWebsitePageConfig',

      ])
    },
    computed: {
      key() {
        return this.$route.path + Math.random();
      }
    },
    components: {
      NavBar,
      Footer,
      BackTop,
      Player
    }
  };
</script>

<style>
    .theme--light.v-application {
        background: rgba(0, 0, 0, 0) !important;
    }

</style>
