import request from './requests'

/*获取网站信息以及博主信息*/
export function getWebsiteInfoConfigApi() {
  return request(
    {
      method: 'get',
      url: "/home/website_info"
    }
  )
}

/*获取网站页面配置信息*/
export function getWebsitePageConfigApi() {
  return request(
    {
      method: 'get',
      url: "/home/website_config"
    }
  )
}


/*获取网站文章列表*/
export function getArticlesListApi(params) {
  return request(
    {
      method: 'get',
      url: "/home/articles/all",
      params
    }
  )
}


/*获取文章详情信息*/
export function getArticlesDetailsApi(params) {
  return request(
    {
      method: 'get',
      url: "/article/details",
      params
    }
  )
}


/*获取当前文章推荐信息*/
export function getArticlesRecommendApi(params) {
  return request(
    {
      method: 'get',
      url: "/article/recommend",
      params
    }
  )
}

/*获取文章归档信息*/
export function getArticlesArchivesApi(params) {
  return request(
    {
      method: 'get',
      url: "/article/archives",
      params
    }
  )
}

/*获取文章分类信息*/
export function getArticlesCategoriesApi(params) {
  return request(
    {
      method: 'get',
      url: "/article/categories",
      params
    }
  )
}


/*获取文章标签信息*/
export function getArticlesTagsApi(params) {
  return request(
    {
      method: 'get',
      url: "/article/tags",
      params
    }
  )
}


/*获取音乐列表*/
export function getMusicsListApi(params) {
  return request(
    {
      method: 'get',
      url: "/article/musics",
      params
    }
  )
}

/*获取友链数据*/
export function getFriendChain() {
  return request(
    {
      method: 'get',
      url: "/article/link"
    }
  )
}

/*获取留言信息*/
export function getMessageListApi() {
  return request(
    {
      url: "/article/message",
      method: 'get',
    }
  )
}

/*添加留言信息*/
export function addMessageApi(params) {
  return request(
    {
      url: "/article/message",
      method: 'post',
      data: params
    }
  )
}


/*实时搜索接口*/
export function RealTimeSearchApi(params) {
  return request(
    {
      url: "/article/search",
      method: 'get',
      params
    }
  )
}


/*添加评论接口*/
export function createCommentsApi(params) {
  return request(
    {
      url: "/article/comments",
      method: 'post',
      data: params
    }
  )
}

/*获取一级评论接口*/
export function getCommentsListApi(params) {
  return request(
    {
      url: "/article/comments",
      method: 'get',
      params
    }
  )
}
/*获取二级评论接口*/
export function getCommentsChildrenListApi(params) {
  return request(
    {
      url: "/article/comments/children",
      method: 'get',
      params
    }
  )
}