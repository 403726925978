<template>
    <div>
        <div id="aplayer"></div>
    </div>
</template>

<script>


  import APlayer from 'aplayer';
  import {getMusicsListApi} from "@/network/interface";

  export default {
    name: "Player",
    data() {
      return {}
    },
    created() {
    },
    mounted() {
      getMusicsListApi({}).then(resp => {
        const ap = new APlayer({
          container: document.getElementById('aplayer'),
          fixed: true,
          autoplay: false,
          volume: 0.3,
          audio: resp.data
        });
      })

    },
  }
</script>

<style scoped>

    #aplayer {
        color: #1f1f1f !important;
    }


</style>
