import axios from "axios";
import Vue from 'vue'


const instance = axios.create({
  // baseURL: 'http://192.168.2.182:8000',
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 5000
})

instance.defaults.headers.put['Content-Type'] = 'application/json'
// 请求前
instance.interceptors.request.use(config => {
    return config;
  },
  error => {
    Vue.prototype.$toast({message: "似乎遇到了bug了~~~", state: "error"})
    return error
  })

// 请求后
instance.interceptors.response.use(response => {
  if (response.data.code === 400) {
    return Promise.reject(response.data.message)
  }
  return response.data
}, error => {
  Vue.prototype.$toast({message: "请求数据失败:" + error, state: "error"})
  return error
})

export default instance