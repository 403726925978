import Toast from "./Toast";
import Vuetify from 'vuetify/lib'; // <---- add  如果 vuetify 有特殊配置可以引入自定义的文件
const obj = {}
obj.install = function (Vue) {

  //  1.创建组件组件构造器
  const toastConstructor = Vue.extend(Toast)
  // 2. new 的方式，根据组件构造器，可以创建出来一个组件对象
  const toast = new toastConstructor()

  toast.$vuetify = new Vuetify().framework; // <---- add

  // 3. 将组件对象手动挂载到某一个元素上
  toast.$mount(document.createElement('div'))
  // 4. toast.$el 对应的就是 div
  document.body.appendChild(toast.$el)


  Vue.prototype.$toast = (options, duration = 2000) => {
    toast.message = options.message
    toast.show = true
    toast.state = options.state

    setTimeout(() => {
      toast.show = false
    }, duration)
  }
}
export default obj
