<template>
    <v-footer class="pa-0  an-blog-InUp">
        <div class="footer-warp">
            <span>Copyright © 2023 by SuperCow</span>
            <p><a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">{{ webInfo.website_records }}</a></p>
        </div>
    </v-footer>
</template>

<script>

    import {WebsiteInfoConfigMixin} from "../mixin";

    export default {
        name: "Footer",
        mixins: [WebsiteInfoConfigMixin]
    }
</script>

<style scoped>

    @keyframes Gradient {
        0% {
            background-position: 0 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0 50%;
        }
    }


    .footer-warp {
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #eeeeee;
        text-align: center;
        height: 136px;
        width: 100%;
        background: linear-gradient(-45deg, #ee7752, #ce3e75, #23a6d5, #23d5ab);
        background-size: 400% 400%;
        animation: Gradient 10s ease infinite;
    }

    .footer-warp a {
        display: block;
        margin-top: 10px;
        color: #eeeeee !important;
    }
</style>
